function NativeBridgeAndroid(){
  NativeBridgeBase.call(this);
}

NativeBridgeAndroid.prototype = Object.create(NativeBridgeBase.prototype);
NativeBridgeAndroid.prototype.constructor = NativeBridgeAndroid;
NativeBridgeAndroid.prototype.call = function call(functionName, args, callback) {

  var hasCallback = callback && typeof callback == "function";
  var callbackId = hasCallback ? this.callbacksCount++ : 0;

  if (hasCallback)
    this.callbacks[callbackId] = callback;

  NativeBridgeConn.call(functionName,args, callbackId);
};
