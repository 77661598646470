// the main entry point to start the search
function matchWholeWord(data, sentence){
  data = data.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const re = new RegExp("(?!-)(?:^|\\W)(" + data + ")(?!\\w)(?!-)", "ig");
  return re.test(sentence);
}

function highlightAllOccurencesOfString(keyword, isSinglePageSpread) {
    removeAllHighlights(isSinglePageSpread);
    HighlightAllOccurencesOfStringForElement(getTheIframeBodyElement(), keyword.toLowerCase());
    if(!isSinglePageSpread){
      HighlightAllOccurencesOfStringForElement(getTheIframeBodyElement(true), keyword.toLowerCase());
    }
}

function HighlightAllOccurencesOfStringForElement(element,keyword) {

  if (element) {
    if (element.nodeType == 3) {        // Text node
      while (true) {
        let value = element.nodeValue;  // Search for keyword in text node
        let idx = value.toLowerCase().indexOf(keyword);

        if (idx < 0 || !matchWholeWord(keyword, value)) break;             // not found, abort

        let span = document.createElement('span');
        let text = document.createTextNode(value.substr(idx,keyword.length));
        span.appendChild(text);
        span.setAttribute('class','Highlight');
        span.style.backgroundColor='#fbd1a2';
        span.style.color='black';
        text = document.createTextNode(value.substr(idx+keyword.length));
        element.deleteData(idx, value.length - idx);
        let next = element.nextSibling;
        element.parentNode.insertBefore(span, next);
        element.parentNode.insertBefore(text, next);
        element = text;
        //SearchResultCount++;  // update the counter
      }
    } else if (element.nodeType == 1) { // Element node
      if (element.style && element.style.display != 'none' && element.nodeName.toLowerCase() != 'select') {
        for (let i=element.childNodes.length-1; i>=0; i--) {
          HighlightAllOccurencesOfStringForElement(element.childNodes[i],keyword);
        }
      }
    }
  }
}


// helper function, recursively removes the highlights in elements and their childs
function RemoveAllHighlightsForElement(element) {
  if (element) {
    if (element.nodeType == 1) {
      if (element.getAttribute('class') == 'Highlight') {
        let text = element.removeChild(element.firstChild);
        element.parentNode.insertBefore(text,element);
        element.parentNode.removeChild(element);
        return true;
      } else {
        let normalize = false;
        for (let i=element.childNodes.length-1; i>=0; i--) {
          if (RemoveAllHighlightsForElement(element.childNodes[i])) {
            normalize = true;
          }
        }
        if (normalize) {
          element.normalize();
        }
      }
    }
  }
  return false;
}

// the main entry point to remove the highlights
function removeAllHighlights(isSinglePageSpread) {
  RemoveAllHighlightsForElement(getTheIframeBodyElement());
  if(!isSinglePageSpread){
    RemoveAllHighlightsForElement(getTheIframeBodyElement(true));
  }
}

function getTheIframeBodyElement(forRightPage){
    let frameIndex = !!forRightPage ? 1 : 0;
    let iframe = document.getElementsByTagName('iframe')[frameIndex]
      , bodyElement = null;
    if (iframe === undefined) {
      return null;
    }
    if ( iframe.contentDocument )
    { // FF
        bodyElement = iframe.contentDocument.getElementsByTagName('body')[0];
    }
    else if ( iframe.contentWindow )
    { // IE
        bodyElement = iframe.contentWindow.document.getElementsByTagName('body')[0];
    }
    return bodyElement;
}
