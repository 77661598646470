function NativeBridgeElectron(){
  NativeBridgeBase.call(this);
  if (window.ipcRenderer) {
    window.ipcRenderer.on('ResultForCallback', function(event, args) {
      this.resultForCallback(args.callbackId, [JSON.stringify(args)]);
    }.bind(this));
  }
}

NativeBridgeElectron.prototype = Object.create(NativeBridgeBase.prototype);
NativeBridgeElectron.prototype.constructor = NativeBridgeElectron;
NativeBridgeElectron.prototype.call = function call(functionName, args, callback) {

  var hasCallback = callback && typeof callback == "function";
  var callbackId = hasCallback ? this.callbacksCount++ : 0;

  if (hasCallback)
    this.callbacks[callbackId] = callback;

  var obj = Object.assign({}, JSON.parse(args));
  obj.callbackId = callbackId;
  if(obj && functionName && window.ipcRenderer){
    window.ipcRenderer.send(functionName, obj);
  }
};
