function NativeBridgeProvider(){}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
NativeBridgeProvider.prototype.getOperatingSystem = function() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "WindowsPhone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream || (window.navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && 'ontouchend' in document)) {
    return "iOS";
  }

  return "unknown";
};

NativeBridgeProvider.prototype.get = function(){
  var os = this.getOperatingSystem();
  if(os === "WindowsPhone" || os === "unknown"){
    return new NativeBridgeElectron();
  }

  if(os === "Android"){
    return new NativeBridgeAndroid();
  }

  if(os === "iOS"){
    return new NativeBridgeIOS();
  }
  return "";

};
