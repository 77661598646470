var ReadiumObj = undefined;

function getReadiumReader() {
  return new Promise(function (resolve, reject) {
    if (!ReadiumObj) {
      require(['readium_js/Readium'], function (Readium) {
        ReadiumObj = Readium;
        createReader(resolve);
      });
    } else {
      createReader(resolve);
    }
  });

}

function createReader(resolve) {
  var readiumOptions = {
    jsLibRoot: "./assets/js/lib/",
    cacheSizeEvictThreshold: undefined,
    useSimpleLoader: false, // false so we can load ZIP'ed EPUBs
    openBookOptions: {}
  };
  var origin = window.location.origin;
  if (!origin) {
    origin = window.location.protocol + '//' + window.location.host;
  }
  var prefix = (self.location && self.location.pathname && origin) ? (origin + self.location.pathname + "/..") : "";
  var readerOptions = {
    needsFixedLayoutScalerWorkAround: false,
    el: "#viewer",
    annotationCSSUrl: prefix + "/annotations.css",
    mathJaxUrl: "/MathJax.js"
  };
  resolve(new ReadiumObj(readiumOptions, readerOptions));
}

function StartTTSreadium() {
  var u = new SpeechSynthesisUtterance();
  u.lang = 'en-US';
  u.voiceURI = 'native';
  u.volume = 1;
  u.rate = 1;
  u.pitch = 2;
  if ($('iframe').length > 1) {
    if ($($('iframe')[0].contentDocument.body).length > 0) {
      u.text = ($($('iframe')[1].contentDocument.body).length > 0 ?
        function () {
          return $($('iframe')[0].contentDocument.body).clone().children(".annotator-outer,.annotator-adder").remove().end().text() + $($('iframe')[1].contentDocument.body).clone().children(".annotator-outer,.annotator-adder").remove().end().text();
        } () :
        function () {
          return $($('iframe')[0].contentDocument.body).clone().children(".annotator-outer,.annotator-adder").remove().end().text() + "";
        } ());
    }
  } else if ($('iframe').length === 1) {
    u.text = function () {
      return $($('iframe')[0].contentDocument.body).clone().children(".annotator-outer,.annotator-adder").remove().end().text();
    } ();
  }
  speechSynthesis.speak(u);
  setTimeout(function(){
    if(speechSynthesis.pending){
      speechSynthesis.resume();
    }
  },0);
}

function getSearchIndexeObj() {
  return elasticlunr(function () {
    this.use(lunr.multiLanguage('en', 'ru', 'de', 'hi'));
    this.addField('title');
    this.addField('idref');
    this.addField('href');
    this.setRef('id');
  });
}
