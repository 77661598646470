/**
 * Created by debayan.das on 18-06-2018.
 */

var NativeBridge = new NativeBridgeProvider().get();
var downloadingBooks = [];

NativeBridge.resultOperation = function resultOperation(resultArr) {
  if (!resultArr) return;
  resultArr = JSON.stringify(resultArr).trim();
  let result = JSON.parse(resultArr);

  if (result.method === "downloadEpub") {
    if (result.status === "complete") {
      removeDownloadingBook(result.bookId);
    }
  }
}

function setLanguageKeyCallback(data, callback) {
  let requestData = {
    "method": "setLanguageKeyCallback",
    "data": data,
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getLanguageKeyCallback(data, callback) {
  let requestData = {
    "method": "getLanguageKeyCallback",
    "data": data,
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getResponseUrlCallback(data, callback) {
  let requestData = {
    "method": "getResponseUrlCallback",
    "data": data,
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getSubscriptionCallback(meeUserId, callback) {
  let requestData = {
    "method": "getSubscriptionCallback",
    "data": meeUserId
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getManifestVersionCallback(manifestVersionInfo, callback) {
  let requestData = {
    "method": "getManifestVersionCallback",
    "data": {
      manifestVersionInfo
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveManifestVersion(responseObject) {
  NativeBridge.call("JSBridge", JSON.stringify(responseObject), "");
}

function getMachineIdentifier() {
  NativeBridge.call("getMachineIdentifier", null, "setMachineIdentifier");
}

function getMachineIdentifierCallback(callback) {
  let requestData = {
    "method": "getMachineIdentifierCallback"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function deleteRedisAPICallback(callback) {
  let requestData = {
    "method": "deleteRedisAPICallback"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveMachineIdentifier(machineIdentifier) {
  let requestData = {
    "data": machineIdentifier
  };
  NativeBridge.call("saveMachineIdentifier", JSON.stringify(requestData), "");
}

function setMachineIdentifier(machineIdentifier) {
  var event = new CustomEvent("setMachineIdentifierEvent", {
    detail: {
      data: machineIdentifier
    }
  });
  window.dispatchEvent(event);
}

function authTokenCallback(authdata, callback) {
  let requestData = {
    "method": "authTokenCallback",
    "data": authdata,
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getTocManifestCallback(series, level, manifestVersion, callback) {
  let requestData = {
    "method": "getTocManifestCallback",
    "data": {
      series,
      level,
      manifestVersion
    }
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback)
}

function getMeeToken(callback) {
  let requestData = {
    "method": "getMeeTokenCallback",
    "data": {}
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback)
}

function saveManifestCallback(responseObject, callback) {
  let requestData = {
    "method": "saveManifestCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getCourseContentCallback(responseObject, callback) {
  let requestData = {
    "method": "getCourseContentCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHotspotManifestCallBack(data, callback) {
  let requestData = {
    "method": "getHotspotManifestCallBack",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getAssignmentCallBack(assignmentObject, callback) {
  let requestData = {
    "method": "getAssignmentCallBack",
    "data": assignmentObject
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getBookmarkNotesDataCallback(contentUnitId, callback) {
  let requestData = {
    "method": "getBookmarkNotesDataCallback",
    "data": contentUnitId
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function saveHomeworkAssessmentCallback(homeworkData, callback) {
  let requestData = {
    "method": "saveHomeworkAssessmentCallback",
    "data": homeworkData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncBookmarkNotesDataCallback(contentUnitId, callback) {
  let requestData = {
    "method": "syncBookmarkNotesDataCallback",
    "data": contentUnitId
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveOrUpdateBookmarkNotesCallback(bookmarkNotesData, callback) {
  let requestData = {
    "method": "saveOrUpdateBookmarkNotesCallback",
    "data": bookmarkNotesData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function deleteBookmarkNotesDataCallback(bookmarkNotesData, callback) {
  let requestData = {
    "method": "deleteBookmarkNotesDataCallback",
    "data": bookmarkNotesData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveUpdateIWBAnnotationsCallback(IWBData, callback) {
  let requestData = {
    "method": "saveUpdateIWBAnnotationsCallback",
    "data": IWBData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getIWBAnnotationsCallback(IWBData, callback) {
  let requestData = {
    "method": "getIWBAnnotationsCallback",
    "data": IWBData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncIWBAnnotationsCallback(IWBData, callback) {
  let requestData = {
    "method": "syncIWBAnnotationsCallback",
    "data": IWBData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function deleteIWBAnnotationsCallback(IWBData, callback) {
  let requestData = {
    "method": "deleteIWBAnnotationsCallback",
    "data": IWBData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveUpdateLastAccessedBookPageCallback(bookLastAccessData, callback) {
  let requestData = {
    "method": "saveUpdateLastAccessedBookPageCallback",
    "data": bookLastAccessData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getLastAccessedBookPageCallback(bookLastAccessData, callback) {
  let requestData = {
    "method": "getLastAccessedBookPageCallback",
    "data": bookLastAccessData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncLastAccessedBookPageCallback(bookLastAccessData, callback) {
  let requestData = {
    "method": "syncLastAccessedBookPageCallback",
    "data": bookLastAccessData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function openExternalURLCallback(data, callback) {
  let requestData = {
    "method": "openExternalURLCallback",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getMasterDetailsCallback(productFrameworkId, callback) {
  let requestData = {
    "method": "getMasterDetailsCallback",
    "data": { productFrameworkId }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function downloadDataCallback(downloadData, callback) {
  let requestData = {
    "method": "downloadDataCallback",
    "data": downloadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function downloadImagesCallback(downloadData, callback) {
  let requestData = {
    "method": "downloadImagesCallback",
    "data": downloadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function downloadThumbnailCallback(downloadData, callback) {
  let requestData = {
    "method": "downloadThumbnailCallback",
    "data": downloadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getDeviceTokenFromMobileCallback(callback) {
  let requestData = {
    "method": "getDeviceTokenFromMobileCallback"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function downloadLanguagesCallback(fileUrlArr, callback) {
  let requestData = {
    "method": "downloadLanguagesCallback",
    "data": {
      "fileUrl": fileUrlArr
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function readLanguageTranslationCallback(fileUrl, callback) {
  let requestData = {
    "method": "readLanguageTranslationCallback",
    "data": {
      "fileUrl": fileUrl
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function checkFileExistsCallback(filePath, callback) {
  let requestData = {
    "method": "checkFileExistsCallback",
    "data": {
      "filePath": filePath
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkFileExistsGlobalCallback(filePath, callback) {
  let requestData = {
    "method": "checkFileExistsGlobalCallback",
    "data": {
      "filePath": filePath
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveAngularVariablesToMobileDevice(data, callback) {
  let requestData = {
    "method": "saveAngularVariablesToMobileDevice",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getIsUserOnlineCallback(callback) {
  let requestPayload = {
    "method": "getIsUserOnlineCallback"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestPayload), callback);
}

function getApplicationVersionCallback(productType, callback) {
  let requestPayload = {
    "method": "getApplicationVersionCallback",
    "data": {
      "productType": productType
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestPayload), callback);
}

function getResourceBankRedirectionUrlCallback(contentunitId, callback) {
  let requestPayload = {
    "method": "getResourceBankRedirectionUrlCallback",
    "data": contentunitId
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestPayload), callback);
}

function sendReport(callback) {
  let requestData = {
    "method": "sendReport"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkKMLIStatusCallback(callback) {
  let requestData = {
    "method": "checkKMLIStatusCallback"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getRedisCacheCallback(callback) {
  let requestData = {
    "method": "getRedisCacheCallback",
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getRedisAPICallback(redisData, callback) {
  let requestData = {
    "method": "getRedisAPICallback",
    "data": redisData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function setRedisCacheCallback(redisData, callback) {
  let requestData = {
    "method": "setRedisCacheCallback",
    "data": redisData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function setRedisAPICallback(redisData, callback) {
  let requestData = {
    "method": "setRedisAPICallback",
    "data": redisData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function logoutUserCallback(meeUserId, callback) {
  let requestData = {
    "method": "logoutUserCallback",
    "data": meeUserId
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getFileDownloadedStatusCallback(fileURLs, callback) {
  let requestData = {
    "method": "getFileDownloadedStatusCallback",
    "data": fileURLs
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkPermissionAndroid(callback) {
  let requestData = {
    "method": "checkPermissionAndroid"
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function orientationHandlerCallback(data) {
  let requestData = {
    "method": "orientationHandlerCallback",
    "data": { "device": data.device, "isBooklaunched": data.isBooklaunched, }
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData));
}

function deleteFilesCallback(fileUrl, deleteAllFlag, contentUnitID,callback) {
  let requestData = {
    "method": "deleteFilesCallback",
    "data": {
      "fileURLs": fileUrl,
      "removeAllStatus": deleteAllFlag,
      "referenceId":contentUnitID
    }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function nativeAnnotation(editor, annotation) {
  let mode = 'add',
    id = '';
  if (annotation.highlights[0].getAttribute('id') !== null) {
    mode = 'update';
    id = annotation.highlights[0].getAttribute('id');
  }
  downloadInfo = {
    "method": "nativeAnnotation",
    "editor": editor,
    "mode": mode,
    "id": id,
    "annotator": 'annotator'
  }
  NativeBridge.call("JSBridge", JSON.stringify(downloadInfo), nativeAnnotationCallback);
}

function nativeAnnotationCallback(data) {
  let dataParsed = JSON.parse(data);
  var event = new CustomEvent("loadNativeAnnotationResponse", {
    detail: {
      data: dataParsed
    },
    bubbles: true,
    cancelable: true
  });
  window.document.dispatchEvent(event);
}

function log(data, callback) {
  let requestData = {
    "method": "log",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function createUpdateCalendarEventCallback(eventData, callback) {
  let requestData = {
    "method": "createUpdateCalendarEventCallback",
    "data": eventData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getCalendarEventDetailsCallback(eventData, type, callback) {
  let requestData = {
    "method": "getCalendarEventDetailsCallback",
    "data": { eventData, type }
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function deleteCalendarEventDetailsCallback(calendarEventId, callback) {
  let requestData = {
    "method": "deleteCalendarEventDetailsCallback",
    "data": { calendarEventId }
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getRedirectUrlCallback(getRedirectUrlParams, callback) {
  let requestData = {
    "method": "getRedirectUrlCallback",
    "data": getRedirectUrlParams
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getUserBasicDetailsCallback(getUserBasicDetailsParams, callback) {
  let requestData = {
    "method": "getUserBasicDetailsCallback",
    "data": getUserBasicDetailsParams
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getZoomUserBasicDetailsCallback(getZoomUserBasicDetailsParams, callback) {
  let requestData = {
    "method": "getZoomUserBasicDetailsCallback",
    "data": getZoomUserBasicDetailsParams
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function createMeetingCallback(createMeetingParams, callback) {
  let requestData = {
    "method": "createMeetingCallback",
    "data": createMeetingParams
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getLastAccessedActivityCallback(data, callback) {
  let requestData = {
    "method": "getLastAccessedActivityCallback",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncLastAccessedActivityCallback(data, callback) {
  let requestData = {
    "method": "syncLastAccessedActivityCallback",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncCompleteStatusCallback(data,callback) {
  let requestData = {
    "method": "syncCompleteStatusCallback",
    "data": data
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getCompletionActivityStatusCallback(ActivityStatusRequestParamsType, callback) {
  let requestData = {
    "method": "getCompletionActivityStatusCallback",
    "data": ActivityStatusRequestParamsType
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getAnswerForActivitySetCallback(ActivityStatusRequestParamsType, callback) {
  let requestData = {
    "method": "getAnswerForActivitySetCallback",
    "data": ActivityStatusRequestParamsType
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getTrophyDetailsCallback(trophyCountData, callback) {
  let requestData = {
    "method": "getTrophyDetailsCallback",
    "data": trophyCountData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function setAnswerForActivitySetCallback(setAnswersForActivitySetOptions, callback) {
  let requestData = {
    "method": "setAnswerForActivitySetCallback",
    "data": setAnswersForActivitySetOptions
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function thirdPartyAuthSuccess() {
  window.postMessage({ zoomAuth: 'true' }, '*');
}

// function handlePushNotificationCallback() {
//   window.postMessage({
//     meeUserId: "This is Test Notification for iOS",
//     click_action : "http://localhost:8080/android_asset/dist/meeplatform/browser/notifications?currentPage=1&selectedDropdownOptionId=5&dropdownId=paginationIdPageSizeList&ver=lfx",
//     title: "Notification Title"}, '*');
// }

function getNotificationDetails(notificationDetails) {

  let jsonData = notificationDetails;
  var event = new CustomEvent("notificationEvent", {
    detail: {
      notificationDetails: jsonData
    }
  });

  window.dispatchEvent(event);
}



function getChallengeActivityInfoCallback(contentUnitIds, callback) {
  let requestData = {
    "method": "getChallengeActivityInfoCallback",
    "data": contentUnitIds
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveUpdateLastAccessedActivityCallback(saveUpdatelastAccessedActivity, callback) {
  let requestData = {
    "method": "saveUpdateLastAccessedActivityCallback",
    "data": saveUpdatelastAccessedActivity
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getActivityQuestionCountCallback(payLoadData, callback) {
  let requestData = {
    "method": "getActivityQuestionCountCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkQuestionBankAndPbaAvailabilityCallback(payLoadData, callback) {
  let requestData = {
    "method": "checkQuestionBankAndPbaAvailabilityCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getPoolPartyDataCallback(payLoadData, callback) {
  let requestData = {
    "method": "getPoolPartyDataCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getComponentTreeStructureCallback(payLoadData, callback) {
  let requestData = {
    "method": "getComponentTreeStructureCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassesStudentsSubgroupsSubscriptionDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "getClassesStudentsSubgroupsSubscriptionDetailsCallback",
    "data": payLoadData
  }
    NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
  }

function getActivityListingCallback(payLoadData, callback) {
  let requestData = {
    "method": "getActivityListingCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getPbaFolderStructureCallback(payLoadData, callback) {
  let requestData = {
    "method": "getPbaFolderStructureCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHomeworkAssessmentDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "getHomeworkAssessmentDetailsCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getDomainClassesCallback(classData, callback) {
  let requestData = {
    "method": "getDomainClassesCallback",
    "data": classData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getDomainClassListCallback(classData, callback) {
  let requestData = {
    "method": "getDomainClassListCallback",
    "data": classData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassesSubGroupsCallback(classData, callback) {
  let requestData = {
    "method": "getClassesSubGroupsCallback",
    "data": classData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getUsersListsForRecipientCallback(getUsersBody, callback) {
  let requestData = {
    "method": "getUsersListsForRecipientCallback",
    "data": getUsersBody
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getAttendeesCallback(eventData, callback) {
  let requestData = {
    "method": "getAttendeesCallback",
    "data": eventData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getClassGradingDetailsCallback(eventData, callback) {
  let requestData = {
    "method": "getClassGradingDetailsCallback",
    "data": eventData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getCoursesByClassIdCallback(eventData, callback) {
  let requestData = {
    "method": "getCoursesByClassIdCallback",
    "data": eventData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getActivityWiseStudentsGradingDetailsCallback(activityData, callback) {
  let requestData = {
    "method": "getActivityWiseStudentsGradingDetailsCallback",
    "data": activityData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function transformUgcToRcfCallback(payLoadData, callback) {
  let requestData = {
    "method": "transformUgcToRcfCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function transformUgcToRcfManifestCallback(payLoadData, callback) {
  let requestData = {
    "method": "transformUgcToRcfManifestCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getCourseWiseStudentProgressSummaryCallback(progressData, callback) {
  let requestData = {
    "method": "getCourseWiseStudentProgressSummaryCallback",
    "data": progressData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getStudentHomeworkAssessmentListCallback(payLoadData, callback) {
  let requestData = {
    "method": "getStudentHomeworkAssessmentListCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHomeworkAssessmentListCallback(objQueryParams, callback) {
  let requestData = {
    "method": "getHomeworkAssessmentListCallback",
    "data": objQueryParams
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassWiseStudentProgressSummaryCallback(classProgressData, callback) {
  let requestData = {
    "method": "getClassWiseStudentProgressSummaryCallback",
    "data": classProgressData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function fetchNonSyncCompletedDataCallback(fetchCompletedData, callback) {
  let requestData = {
    "method": "fetchNonSyncCompletedDataCallback",
    "data": fetchCompletedData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function addEditUgcQuestionDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "addEditUgcQuestionDetailsCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function updateControlledStartForAssessmentCallback(payLoadData, callback) {
  let requestData = {
    "method": "updateControlledStartForAssessmentCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassWiseStudentsPerformanceSummaryCallback(payLoadData, callback) {
  let requestData = {
    "method": "getClassWiseStudentsPerformanceSummaryCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassWiseClassworkPerformanceSummaryCallback(classWiseClassworkPerformanceData, callback) {
  let requestData = {
    "method": "getClassWiseClassworkPerformanceSummaryCallback",
    "data": classWiseClassworkPerformanceData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getUserWiseActivityWiseAttemptDetailsCallback(userWiseActivityWiseAttemptData, callback) {
  let requestData = {
    "method": "getUserWiseActivityWiseAttemptDetailsCallback",
    "data": userWiseActivityWiseAttemptData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassAverageForSelectedActivityCallback(classWiseAverageForSelectedActivityData, callback) {
  let requestData = {
    "method": "getClassAverageForSelectedActivityCallback",
    "data": classWiseAverageForSelectedActivityData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassGradingSummaryCallback(classGradingSummaryData, callback) {
  let requestData = {
    "method": "getClassGradingSummaryCallback",
    "data": classGradingSummaryData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getActivityWiseStudentsGradingSummaryCallBack(activityWiseStudentsGradingSummaryData, callback) {
  let requestData = {
    "method": "getActivityWiseStudentsGradingSummaryCallBack",
    "data": activityWiseStudentsGradingSummaryData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getStudentHomeworkAssessmentDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "getStudentHomeworkAssessmentDetailsCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function setStudentActivityDetailsCallback(setAnswersForActivitySetOptions, callback) {
  let requestData = {
    "method": "setStudentActivityDetailsCallback",
    "data": setAnswersForActivitySetOptions
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function copyHomeworkAssessmentCallback(payLoadData, callback) {
  let requestData = {
    "method": "copyHomeworkAssessmentCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function deleteHomeworkAssessmentCallback(payLoadData, callback) {
  let requestData = {
    "method": "deleteHomeworkAssessmentCallback",
    "data": payLoadData
  };
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveUpdateUserDeviceTokenDetailsCallback(callback) {
  console.log("calling native bridge");
  let requestData = {
    "method": "saveUpdateUserDeviceTokenDetailsCallback",
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

// function setNotificationToUserCallback() {
//   window.postMessage({ data: {success:"success"} }, '*');
// }

function setShowHideActivityCallback(responseObject, callback) {
  let requestData = {
    "method": "setShowHideActivityCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getNotificationDetailsCallback(params, callback) {
  let requestData = {
    "method": "getUserNotificationsCallback",
    "data": params,
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getUserUnReadNotificationsCountCallback(callback) {
  let requestData = {
    "method": "getUserUnReadNotificationsCountCallback",
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function viewUserNotificationsCallback(params,callback) {
  let requestData = {
    "method": "markUserNotificationAsReadCallback",
    "data": params,
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function deleteUserNotificationsCallback(params,callback) {
  let requestData = {
    "method": "deleteUserNotificationsCallback",
    "data":params
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkForPendingPushNotificationsCallback(callback){
  let requestData = {
    "method": "checkForPendingPushNotificationsCallback"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkPaperTestProgressStatusCallback(responseObject, callback) {
  let requestData = {
    "method": "checkPaperTestProgressStatusCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function generatePaperTestArtifactCallback(responseObject, callback) {
  let requestData = {
    "method": "generatePaperTestArtifactCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getStudentActivityDetailsByActivitySetIdCallback(responseObject, callback) {
  let requestData = {
    "method": "getStudentActivityDetailsByActivitySetIdCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getRcfPlayableDataCallback(responseObject, callback) {
  let requestData = {
    "method": "getRcfPlayableDataCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getUgcRequestJsonCallback(responseObject, callback) {
  let requestData = {
    "method": "getUgcRequestJsonCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncStudentHomeworkDelete(responseObject, callback) {
  let requestData = {
    "method": "syncStudentHomeworkDelete",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getRcfPlayableBulkDataCallback(responseObject, callback) {
  let requestData = {
    "method": "getRcfPlayableBulkDataCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function homeworkDownloadedListCallback(responseObject, callback) {
  let requestData = {
    "method": "homeworkDownloadedListCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function removeUpdateHomeworkStatusCallback(responseObject, callback) {
  let requestData = {
    "method": "removeUpdateHomeworkStatusCallback",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncStudentHomeworkActivity(callback) {
  let requestData = {
    "method": "syncStudentHomeworkActivity"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertModuleDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "getInsertModuleDetailsCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertHomeworkModuleDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "getInsertHomeworkModuleDetailsCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertAssessmentModuleDetailsCallback(payLoadData, callback) {
  let requestData = {
    "method": "getInsertAssessmentModuleDetailsCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function validateJoiningCodeCallback(joiningCode, callback) {
  let requestData = {
    "method": "validateJoiningCodeCallback",
    "data": joiningCode
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function joinClassOrInstituteCallback(payLoad, callback) {
  let requestData = {
    "method": "joinClassOrInstituteCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassSubgroupDetailsCallback(callback) {
  let requestData = {
    "method": "getClassSubgroupDetailsCallback"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertAnnotationModuleDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getInsertAnnotationModuleDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertNavioStudentAvatarGuideRewardsInfoCallback(payLoad, callback) {
  let requestData = {
    "method": "getInsertNavioStudentAvatarGuideRewardsInfoCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function updateStudentRelatedDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "updateStudentRelatedDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertHomeworkStudentModuleDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getInsertHomeworkStudentModuleDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkCurrentUserEligibilityForMigrationCallback(callback) {
  let requestData = {
    "method": "checkCurrentUserEligibilityForMigrationCallback"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function checkUsersFinalDataMigrationStatusCallback(callback) {
  let requestData = {
    "method": "checkUsersFinalDataMigrationStatusCallback"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertAssessmentStudentModuleDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getInsertAssessmentStudentModuleDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertHomeworkStudentRemainingAttemptsDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getInsertHomeworkStudentRemainingAttemptsDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getInsertEplannerModuleDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getInsertEplannerModuleDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function updateLoginTokenCallback(payLoad, callback) {
  let requestData = {
    "method": "updateLoginTokenCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function callForProcessUserMigratedDataCallback(callback) {
  let requestData = {
    "method": "callForProcessUserMigratedDataCallback",
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getUserMigrationStatusCallback(callback) {
  let requestData = {
    "method": "getUserMigrationStatusCallback"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getComponentClassAverageByStudentCallback(payLoad,callback) {
  let requestData = {
    "method": "getComponentClassAverageByStudentCallback",
    "data":payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getStudentWiseClassworkPerformanceSummaryCallback(payLoad,callback) {
  let requestData = {
    "method": "getStudentWiseClassworkPerformanceSummaryCallback",
    "data":payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getStudentWiseHomeworkAssessmentCardSummaryCallback(payLoad,callback) {
  let requestData = {
    "method": "getStudentWiseHomeworkAssessmentCardSummaryCallback",
    "data":payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getHomeworkAssessmentStudentsRequiredGradingDetailsCallback(payLoad,callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentsRequiredGradingDetailsCallback",
    "data":payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getHomeworkAssessmentStudentsCompletedGradingDetailsCallback(payLoad,callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentsCompletedGradingDetailsCallback",
    "data":payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getHomeworkAssessmentStudentsPendingGradingDetailsCallback(payLoad,callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentsPendingGradingDetailsCallback",
    "data":payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function saveCsvFileCallback(payLoad, callback) {
  let requestData = {
    "method": "saveCsvFileCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHomeworkAssessmentStudentsExemptedDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentsExemptedDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function addUpdateStudentHAFeedbackCallback(payLoad, callback) {
  let requestData = {
    "method": "addUpdateStudentHAFeedbackCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getStudentHAFeedbackCallback(payLoad, callback) {
  let requestData = {
    "method": "getStudentHAFeedbackCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function addUpdateExemptStudentHomeworkAssessmentCallback(payLoad, callback) {
  let requestData = {
    "method": "addUpdateExemptStudentHomeworkAssessmentCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getFilterClassListCallback(payLoad, callback) {
  let requestData = {
    "method": "getFilterClassListCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHomeworkAssessmentStudentSummaryCallback(payLoad, callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentSummaryCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHomeworkAssessmentStudentSummaryDetailCallback(payLoad, callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentSummaryDetailCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getPaginatedSubscriptionDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getPaginatedSubscriptionDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getHomeworkAssessmentStudentsGradingSummaryCallback(payLoad, callback) {
  let requestData = {
    "method": "getHomeworkAssessmentStudentsGradingSummaryCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getClassWiseHomeworkAssessmentCardSummaryCallback(payLoad, callback) {
  let requestData = {
    "method": "getClassWiseHomeworkAssessmentCardSummaryCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getStudentsListByClassIdCallback(payLoad, callback) {
  let requestData = {
    "method": "getStudentsListByClassIdCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getStudentWiseActivityPerformanceDetailsStudentViewCallback(payLoad, callback) {
  let requestData = {
    "method": "getStudentWiseActivityPerformanceDetailsStudentViewCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getUnitWiseCourseWorkStudentPerformanceDetailsStudentViewCallback(payLoad, callback) {
  let requestData = {
    "method": "getUnitWiseCourseWorkStudentPerformanceDetailsStudentViewCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getLessonWiseCourseWorkStudentPerformanceDetailsStudentViewCallback(payLoad, callback) {
  let requestData = {
    "method": "getLessonWiseCourseWorkStudentPerformanceDetailsStudentViewCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getClassAverageForSelectedLessonCallback(payLoad, callback) {
  let requestData = {
    "method": "getClassAverageForSelectedLessonCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getClassAverageForSelectedUnitCallback(payLoad, callback) {
  let requestData = {
    "method": "getClassAverageForSelectedUnitCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getUnitWiseCourseworkStudentPerformanceDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getUnitWiseCourseworkStudentPerformanceDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getLessonWiseCourseworkStudentPerformanceDetailsCallback(payLoad, callback) {
  let requestData = {
    "method": "getLessonWiseCourseworkStudentPerformanceDetailsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getAssignmentStudentsListCallback(payLoad, callback) {
  let requestData = {
    "method": "getAssignmentStudentsListCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getClassAverageForAssignmentCallback(payLoad, callback) {
  let requestData = {
    "method": "getClassAverageForAssignmentCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function addUpdateStudentsComponentFeedbackCallback(payLoad, callback) {
  let requestData = {
    "method": "addUpdateStudentsComponentFeedbackCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function getStudentProgressDetailsByCourseCallback(payLoad, callback) {
  let requestData = {
    "method": "getStudentProgressDetailsByCourseCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function exportClassWiseStudentProgressSummaryCallback(payLoad, callback) {
  let requestData = {
    "method": "exportClassWiseStudentProgressSummaryCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}
function postCommonCallback(payLoad, callback) {
  let requestData = {
    "method": "postCommonCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getPaperAssessmentGradingQuestionsCallback(payLoad, callback) {
  let requestData = {
    "method": "getPaperAssessmentGradingQuestionsCallback",
    "data": payLoad
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function SetLocalUserPreferenceCallback (responseObject, callback) {
  let requestData = {
    "method": "SetLocalUserPreferenceCallback ",
    "data": responseObject
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getLocalUserPreferenceCallback(meeUserId, keyString, callback) {
  let requestData = {
    "method": "getLocalUserPreferenceCallback",
    "data": {
      meeUserId,
      keyString
    }
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback)
}
function setLocalUserPreferenceCallback(meeUserId, keyString, value, callback) {
  let requestData = {
    "method": "setLocalUserPreferenceCallback",
    "data": {
      meeUserId,
      keyString,
      value
    }
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback)
}

function getThemesCallback(callback) {
  console.log("calling native bridge");
  let requestData = {
    "method": "getThemesCallback",
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getCurrentThemeCallback(callback) {
  console.log("calling native bridge");
  let requestData = {
    "method": "getCurrentThemeCallback",
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getEditProfileCallback(payLoadData,callback) {
  let requestData = {
    "method": "getEditProfileCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function getBase64AudioCallback(payLoadData,callback) {
  let requestData = {
    "method": "getBase64AudioCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function syncAudioCallback(callback) {
  let requestData = {
    "method": "syncAudioCallback",
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function downloadAssetBatchesCallback(payLoadData, callback) {
  let requestData = {
    "method": "downloadAssetBatchesCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function downloadResponseInitializer(callback) {
  let requestData = {
    "method": "downloadResponseInitializer"
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}

function cancelAssetBatchesCallback(payLoadData, callback) {
  let requestData = {
    "method": "cancelAssetBatchesCallback",
    "data": payLoadData
  }
  NativeBridge.call("JSBridge", JSON.stringify(requestData), callback);
}