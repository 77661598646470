var userName = "",
    bookTitle = "",
    bookId = "",
    app = null,
    allAnnotations = [];

function setUserForAnnotations(_userName) {
    userName = "";
    userName = _userName;
}

function setBookTitle(_bookId, _bookTitle) {
    bookId = _bookId;
    bookTitle = _bookTitle;
}

function setReadiumAnnotations(annotations) {
    allAnnotations = annotations;
}
require(['readium_js_plugins'], function (Plugins) {

    Plugins.register("annotation", function (api) {

        api.plugin.warn('Example warning. Used when this plugin is initialized.');

        api.reader.on(ReadiumSDK.Events.CONTENT_DOCUMENT_LOADED, function ($iframe, spineItem) {
            (function ($iframe) {

                var hCss1 = document.createElement('link');
                hCss1.rel = 'stylesheet';
                let folderPath = 'documentRootPath';
                let distPath = '/dist';
                if (GlobalConfig.isBrowser) {
                    var browserBasePath = window.location.protocol + '//' + document.location.hostname;
                    if (window.location.port) {
                        browserBasePath += ':' + window.location.port;
                    }
                }
                if (window['GlobalConfig']['isInNative'] === true) {
                    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    if (/android/i.test(userAgent)) {
                        hCss1.href = "http://localhost:" + window.location.port + '/android_asset/dist/meeplatform/browser/assets/app/external.stylesheet.css';
                    } else {
                        hCss1.href = "http://localhost:" + window.location.port + '/dist/meeplatform/browser/assets/app/external.stylesheet.css';
                    }
                }else {
                    if (GlobalConfig.isBrowser) {
                        hCss1.href = browserBasePath + '/assets/app/external.stylesheet.css';
                    } else {
                        hCss1.href = "http://localhost:" + window.location.port + '/assets/app/external.stylesheet.css';
                    }
                }

                //---------------------------------end --------------------------------------------

                target = $iframe[0].contentDocument.head;
                target.insertBefore(hCss1, target.firstChild);
                var hCss = document.createElement('link');
                hCss.rel = 'stylesheet';
                if (window['GlobalConfig']['isInNative'] === true) {
                    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    if (/android/i.test(userAgent)) {
                        hCss.href = "http://localhost:" + window.location.port + '/android_asset/dist/meeplatform/browser/assets/epubjs/external.stylesheet.css';
                    } else {
                        hCss.href = "http://localhost:" + window.location.port + '/dist/meeplatform/browser/assets/epubjs/external.stylesheet.css';
                    }
                }
                else {
                    if (GlobalConfig.isBrowser) {
                        hCss.href = browserBasePath + '/assets/epubjs/external.stylesheet.css';
                    } else {
                        hCss.href = "http://localhost:" + window.location.port + '/assets/epubjs/external.stylesheet.css';
                    }
                }

                //---------------------------------end --------------------------------------------

                var frame = $iframe[0];
                var target = $iframe[0].contentDocument.head;
                target.appendChild(hCss);
                var jScript = document.createElement('script'),
                    r = false;
                jScript.type = "text/javascript";
                jScript.async = false;

                if (window['GlobalConfig']['isInNative'] === true) {

                    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    if (/android/i.test(userAgent)) {
                        jScript.src = "http://localhost:" + window.location.port + '/android_asset/dist/meeplatform/browser/assets/epubjs/external_bundle.js';
                    } else {
                        jScript.src = "http://localhost:" + window.location.port + '/dist/meeplatform/browser/assets/epubjs/external_bundle.js';
                    }
                }
                else {

                    if(GlobalConfig.isBrowser) {
                        jScript.src = browserBasePath + '/assets/epubjs/external_bundle.js';
                    } else {
                        jScript.src = "http://localhost:" + window.location.port + '/assets/epubjs/external_bundle.js';
                    }
                }

                jScript.onload = jScript.onreadystatechange = function () {
                    if (!r) {
                        var obj = JSON.parse(api.reader.bookmarkCurrentPage());
                        var chapter = {};
                        chapter.bookTitle = bookTitle;
                        chapter.currentChapter = {};
                        chapter.currentChapter.id = obj.idref;
                        var pageCfi = obj.contentCFI,
                            hightLightColorCode = "yellow",
                            annotationsPagewise = [];
                        if (spineItem && spineItem.spine && spineItem.spine.package) {
                            if (spineItem.spine.package.rootUrl) {
                                windowURL = spineItem.spine.package.rootUrl + "/" + spineItem.href;
                            }
                        }

                        if (api.reader.annotations) {
                            var arr = [];
                            //Filter the annotations object according to current path
                            api.reader.annotations.forEach(function (element) {
                                const tempURI = element.uri.replace('.epub', '');
                                const tempWindowURL = windowURL.replace('.epub', '');

                                let uriLastPart = decodeURI(tempURI).split("/");
                                let getLastPart = uriLastPart[uriLastPart.length - 1];
                                if (decodeURI(tempWindowURL).indexOf(getLastPart) !== -1) {
                                    arr.push(element);
                                }

                                //if (decodeURI(tempURI) === decodeURI(tempWindowURL)) {
                                //  arr.push(element);
                                //}
                            }, this);
                            annotationsPagewise = arr;
                        }
                        var data = { chapter: chapter, annotationsPagewise: annotationsPagewise, pageCfi: pageCfi, userName: userName, windowURL: windowURL };
                        var event = new CustomEvent('annotationsLoaded', { detail: data });
                        if (window['GlobalConfig']['isInNative'] === true) {
                            setTimeout(function () {
                                try {
                                    frame.contentWindow.document.dispatchEvent(event);
                                } catch (e) {

                                }
                            }, 2000);
                        } else {
                            frame.contentWindow.document.dispatchEvent(event);
                        }
                        // frame.contentWindow.postMessage({ chapter: chapter, annotationsPagewise: annotationsPagewise, pageCfi: pageCfi, userName: userName, windowURL: windowURL }, "*");
                    }
                };
                target.appendChild(jScript);
            })($iframe);
        });
    });
    return true;

});
//merger with master


