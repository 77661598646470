function NativeBridgeIOS(){
  NativeBridgeBase.call(this);
}

NativeBridgeIOS.prototype = Object.create(NativeBridgeBase.prototype);
NativeBridgeIOS.prototype.constructor = NativeBridgeIOS;
NativeBridgeIOS.prototype.call = function call(functionName, args, callback) {

  var hasCallback = callback && typeof callback == "function";
  var callbackId = hasCallback ? this.callbacksCount++ : 0;

  if (hasCallback)
    this.callbacks[callbackId] = callback;

  if (window.webkit) {
    window.webkit.messageHandlers.observe.postMessage("js-frame:" + functionName + ":" + callbackId + ":" + encodeURIComponent(args));
  }

  var iframe = document.createElement("IFRAME");
  iframe.setAttribute("src", "js-frame:" + functionName + ":" + callbackId + ":" + encodeURIComponent(args));
  document.documentElement.appendChild(iframe);
  iframe.parentNode.removeChild(iframe);
  iframe = null;
};
